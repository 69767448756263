import * as React from "react";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { Icon, Dropdown, Select } from "semantic-ui-react";
import "./Sidebar.css";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
import { sidebarOpen as sidebarOpenAtom } from "./Atoms";
import { useRecoilState } from "recoil";
import Logo from "../images/logo-pattern-analytics.svg";
import {
	noDashboards as noDashboardsAtom,
	dashID as dashIDAtom,
	loading as loadingAtom,
	openPanel as openPanelAtom,
	selectedDash as selectedDashAtom,
	brand as brandAtom,
} from "./Atoms";
import axios from "axios";
import { Auth } from "aws-amplify";
import Panel from "./Panel";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "./Drawer.css";

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
	backgroundColor: "#333333",
	overflowY: "hidden    ",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 2px)`,
	backgroundColor: "#333333",
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(6)} + 8px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "flex-start",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	backgroundColor: "#333333",
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export default function MiniDrawer({ setAuthenticated }) {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [user, setUser] = useState();
	const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarOpenAtom);
	const [dashID, setDashID] = useRecoilState(dashIDAtom);
	const [loading, setLoading] = useRecoilState(loadingAtom);
	const [openPanel, setOpenPanel] = useRecoilState(openPanelAtom);
	const [selectedDash, setSelectedDash] = useRecoilState(selectedDashAtom);
	const [noDashboards, setNoDashboards] = useRecoilState(noDashboardsAtom);
	const [selected, setSelected] = useState();
	const [brand, setBrand] = useRecoilState(brandAtom);

	const [dashboards, setDashboards] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [expanded, setExpanded] = React.useState(false);

	const brands = [
		{ key: "FOM", text: "FOM", value: "FOM" },
		// { key: "Birkenstock", text: "Birkenstock", value: "Birkenstock" },
		// { key: "Crocs", text: "Crocs", value: "Crocs" },
		// { key: "2XU", text: "2XU", value: "2XU" },
	];

	async function signOut() {
		try {
			setNoDashboards(false);
			setDashboards([]);
			setDashID("");
			setSelectedDash("");
			setOpenPanel("");
			await Auth.signOut();
			setAuthenticated(false);
		} catch (error) {
			console.log("error signing out: ", error);
		}
	}

	async function getDashboards() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const response = await axios.get(
			`${process.env.REACT_APP_ENDPOINT}/dashboards?email=${user.username}`,
			{
				headers: {
					Authorization: idToken,
				},
			}
		);
		if (response.data.name === "ConnectionError") {
			console.log("Connection Error");
			setLoading(false);
			toast.error("Connection Error");
		} else {
			if (response?.data.length > 0) {
				if (selectedDash === "") {
					setDashID(response.data[0]?.dashboard_id);
					setSelectedDash(response.data[0]?.dashboard_name);
					setOpenPanel(response.data[0]?.department_name);
					let d = [...response.data];
					console.log(d);
					// d.push({
					// 	dashboard_id: "2",
					// 	dashboard_name: "Invoice Analysis",
					// 	dashboard_sequence: null,
					// 	dashboard_type: "UPLOAD",
					// 	department_name: "SAP Extracts",
					// 	department_sequence: 7,
					// 	display_name: null,
					// 	user_id: "dfd97c65-328e-4178-975b-69bfc7c76fff",
					// });
					setDashboards(d);
				}
			}

			if (response?.data.length === 0) {
				setNoDashboards(true);
			}
		}

		return response.data;
	}

	const dashboardQuery = useQuery("dashboards", getDashboards);

	useEffect(() => {
		async function getUser() {
			const u = await Auth.currentAuthenticatedUser();
			setUser(u.username);
		}
		getUser();
	}, []);

	useEffect(() => {
		if (dashboards) {
			const departmentList = [
				...new Set(dashboards?.map((dash) => dash.department_name)),
			];
			setDepartments(departmentList);
		}
	}, [dashboards]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleDrawer = () => {
		setOpen(!open);
	};

	const handlePanel = (panel) => {
		setOpen(true);
	};

	function handleBrand(event, data) {
		console.log(data);
		setBrand(data.value);
	}

	const useStyles = makeStyles((theme) => ({
		root: {
			width: "100%",
			// backgroundColor: "white",
			// backgroundColor: "white",
			backgroundColor: "#333333",
			color: "white",
			height: "70vh",
			overflowY: "scroll",
			// visibility: sidebarOpen? "visible": "hidden"
			// paddingLeft: "10px",
		},
		nested: {
			height: "48px !important",
			paddingLeft: theme.spacing(4),
			fontSize: "14px",
			color: "#999999 !important",
			fontWeight: 500,
			fontFamily: "Montserrat, sans-serif",
		},

		heading: {
			fontSize: theme.typography.pxToRem(15),
			flexBasis: "33.33%",
			flexShrink: 0,
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
	}));

	const classes = useStyles();
	return (
		<Box sx={{ display: "flex", flexDirection: "row" }}>
			<CssBaseline />
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<div className="branding">
						<img src={Logo} alt="logo" width="200px" height="123.60px" />
						<div
							style={{
								marginLeft: "30px",
								marginBottom: "30px",
								color: "white",
								position: "relative",
								zIndex: 10,
								visibility: open ? "visible" : "hidden",
							}}
						>
							<Select
								disabled={true}
								onChange={handleBrand}
								options={brands}
								placeholder={brand}
							/>
						</div>
					</div>
				</DrawerHeader>
				<Divider />
				<div style={{ zIndex: -10 }}>
					<List
						component="ul"
						aria-labelledby="nested-list-subheader"
						className={classes.root}
					>
						{departments.map((department) => {
							return (
								<Panel
									selectedDash={selectedDash}
									mainOpen={open}
									setMainOpen={setOpen}
									key={department}
									department={department}
									dashboards={dashboards}
								/>
							);
						})}
					</List>
				</div>
				<div
					style={{
						visibility: open ? "visible" : "hidden",
						width: "100%",
						position: "absolute",
						bottom: "30px",
					}}
				>
					<button onClick={signOut} className="log-out">
						Logout
					</button>
				</div>
			</Drawer>
			<div className="toggle" onClick={handleDrawer}>
				<Icon
					style={{ color: "white" }}
					name={open ? "chevron left" : "chevron right"}
				/>
			</div>
		</Box>
	);
}
