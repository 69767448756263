import React, { useState, useEffect } from "react";
import axios from "axios";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Auth } from "aws-amplify";
import { Button, Select, Divider, Form, Label } from "semantic-ui-react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ReactComponent as Spinner } from "../images/Spinner.svg";
import { toast } from "react-toastify";
import DocumentList from "./DocumentList";

const Downloads = () => {
	const queryClient = useQueryClient();

	async function getLog() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		// const data = await axios.get(`${process.env.REACT_APP_ENDPOINT}/extractlog`, {
		// 	headers: {
		// 		Authorization: idToken,
		// 	},
		// });
		// return data.data;
	}

	const log = useQuery("log", getLog);

	return (
		<div
			style={{
				backgroundColor: "white",
				display: "flex",
				flexDirection: "column",
				height: "100vh"
			}}
		>	<div style={{alignSelf: "flex-start", margin: "20px"}}>
				<h1>Documents</h1>
			</div>
			<Divider />
				<div>
					{log.isLoading ? (
						// <img src={Spinner} alt="Spinner" />
						<p>Loading...</p>
					) : (
						<DocumentList/>
					)}
				</div>
		</div>
	);
};

export default Downloads;
