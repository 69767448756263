import React, { useState } from "react";
import Logo from "../../images/logo-pattern-analytics.svg";
import { Input, Icon } from "semantic-ui-react";
import { ReactComponent as Spinner } from "../../images/Spinner.svg";
import { Auth } from "aws-amplify";
import { ToastContainer, toast } from 'react-toastify';
import "./Login.css";
// import * as AWS from "aws-sdk/global";
// import {
// 	token as tokenAtom,
// 	author as authorAtom,
// 	accountID as accountIDAtom,
// 	federated as federatedAtom,
// } from "../Atoms";

const Login = ({ authenticated, setAuthenticated }) => {
	const [loginState, setLoginState] = useState({
		username: "",
		password: "",
	});
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordRequired, setNewPasswordRequired] = useState(false);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
	const [forgotPasswordSubmit, setForgotPasswordSubmit] = useState(false);
	const [confirmationCode, setConfirmationCode] = useState("");
	const [enterCode, setEnterCode] = useState(false);
	const [authenticatedUser, setAuthenticatedUser] = useState();

	// const [apiData, setApiData] = useState(null);
	// const [token, setToken] = useRecoilState(tokenAtom);
	// const [author, setAuthor] = useRecoilState(authorAtom);
	// const [accountID, setAccountID] = useRecoilState(accountIDAtom);

	const [forgotPW, setForgotPW] = useState(false);

	const [incorrect, setIncorrect] = useState(false);

	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (loginState.username === "" || loginState.password === "") {
		} else setLoading(true);
		try {
			const user = await Auth.signIn(loginState.username, loginState.password);
			setAuthenticatedUser(user);
			// console.log(user);
			if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				setLoading(false);
				setNewPasswordRequired(true);
			}
			else {
				setLoading(false);
				setAuthenticated(true);
			}
		} catch (error) {
			toast.error(error.message);
			setLoading(false);
			console.log("error signing in", error);
		}
	};

	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setLoginState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleNewPassword = (e) => {
		setNewPassword(e.target.value);
	};

	const handleNewPasswordSubmit = async (e) => {
		e.preventDefault();
		try {
			const auth = await Auth.completeNewPassword(authenticatedUser, newPassword);
			console.log(auth);
			if (auth.challengeName === undefined) {
				setLoading(false);
				setAuthenticated(true);
			}
		}
		catch(error) {
			setLoading(false);
			toast.error(error.message);
		}
	}

/************************************************************************************ */	

	const handleForgotPasswordEmailSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {

			const auth = await Auth.forgotPassword(loginState.username);
			setLoading(false);
			setForgotPassword(false);
			setForgotPasswordSubmit(true);
			console.log(auth);
		}
		catch(e) {
			setLoading(false);
			toast.error(e.message);
		}


	}

	const handleForgotNewPasswordSubmit = async (e) => {
		setLoading(false);
		e.preventDefault();
		try {
			const auth = await Auth.forgotPasswordSubmit(loginState.username, confirmationCode, newPassword);
			if (auth === "SUCCESS") {
				toast.success("Password reset.")
				setForgotPasswordSubmit(false);
			}
		}
		catch(e) {
			toast.error(e.message);
		}
	}

	const buttonsStyle = {
		// display: "flex",
		// flexDirection: "row",
		// justifyContent: "space-evenly",
		// width: "100%",
		// marginTop: "80px",
	};

	if (newPasswordRequired) {
		return (
			<>
			<form className="login" onSubmit={handleNewPasswordSubmit}>
				<ToastContainer 
						position="top-right"
						autoClose={5000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover/>
			<div style={{height: "80%", transform: "translateY(-10vh)"}}>
			{/* <img className="login-logo" src={Logo} alt="login-logo" /> */}
				<div className="login-container">
					<h3>Enter New Password</h3>
					{/* <h4>
						Password <span style={{ color: "#efb758" }}>*</span>
					</h4> */}
						<Input
							name="newPassword"
							type="password"
							placeholder=""
							value={newPassword}
							id="newPassword"
							onChange={handleNewPassword}
							required
						/>
					<div className="login-create">
							<div style={buttonsStyle}>
								<button
									className="sign-in-button hvr-shutter-in-vertical"
								>
									{loading? "SIGNING IN": "SUBMIT"}
								</button>
							</div>
					</div>
				</div>
			</div>
			</form>
			)
		</>
		)
	}

	if (forgotPassword) {
		return (
			<>
			<form className="login" onSubmit={handleForgotPasswordEmailSubmit}>
				<ToastContainer 
						position="top-right"
						autoClose={5000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover/>
			<div style={{height: "80%", transform: "translateY(-10vh)"}}>
			{/* <img className="login-logo" src={Logo} alt="login-logo" /> */}
			<div className="login-container">
				<h3>Enter Email Address</h3>
					<Input
						placeholder="Email..."
						name="username"
						value={loginState.username}
						// id="login-email"
						onChange={handleChange}
						required
					/>
					<div>
					<h5 className="forgot" style={{marginTop: "20px"}} onClick={() => {setForgotPassword(false); setLoading(false)}}>
							Back to Sign In
					</h5>
						<button className="sign-in-button hvr-shutter-in-vertical">
							{!loading? "SEND CODE": "SENDING"}
						</button>
					</div>
			</div>
				</div>
			</form>
			)
		</>
		)
	}

	if (forgotPasswordSubmit) {
		return (
			<>
			<form className="login" onSubmit={handleForgotNewPasswordSubmit}>
				<ToastContainer 
						position="top-right"
						autoClose={5000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover/>
			<div  style={{height: "80%", transform: "translateY(-10vh)"}}>
				<div className="login-container"  >
					{/* <h3>Enter Email Address</h3>
					<Input
						placeholder="Email..."
						name="username"
						value={forgotPasswordEmail}
						id="login-email"
						onChange={(event) => setForgotPasswordEmail(event.target.value)}
						required
					/> */}
					<h3>Enter Verification Code</h3>
					<Input
						placeholder="Code..."
						name="code"
						value={confirmationCode}
						id="login-email"
						onChange={(event) => setConfirmationCode(event.target.value)}
						required
					/>
					<h3>Enter New Password</h3>
					<Input
						placeholder="Password..."
						name="password"
						type="password"
						value={newPassword}
						id="login-email"
						onChange={(event) => setNewPassword(event.target.value)}
						required
					/>
						<h5 className="forgot" onClick={() => {setForgotPasswordSubmit(false); setLoading(false)}}>
							Back to Sign In
						</h5>
					<div className="login-create">
						{!loading ? (
							<div style={buttonsStyle}>
								<button
									// onClick={handleForgotNewPasswordSubmit}
									className="sign-in-button hvr-shutter-in-vertical"
								>
									SUBMIT
								</button>
							</div>
						) : (
							<Spinner className="spinner" />
						)}
					</div>
				</div>
			</div>

			</form>
			)
		</>
		)
	}

	return (
		<>
			<form className="login" onSubmit={handleSubmit}>
				<ToastContainer 
						position="top-right"
						autoClose={5000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover/>
			<div style={{height: "80%", transform: "translateY(-10vh)"}}>
				<div className="login-container">
					<h3>Sign in to your account</h3>
					<h4>
						Email <span style={{ color: "#efb758" }}>*</span>
					</h4>
					<Input
						placeholder="Email..."
						name="username"
						value={loginState.email}
						id="login-email"
						onChange={handleChange}
						required
					/>
					<h4>
						Password <span style={{ color: "#efb758" }}>*</span>
					</h4>
					<Input
						name="password"
						type="password"
						placeholder="Enter your password..."
						value={loginState.password}
						id="login-password"
						onChange={handleChange}
						required
					/>
						<h5 className="forgot" onClick={() => setForgotPassword(true)}>
							Forgot password? Reset password
						</h5>
					{incorrect ? (
						<h6 className="incorrect">
							The username or password you have entered is incorrect
						</h6>
					) : null}
					<div className="login-create">
						{!loading ? (
							<div>
								<button
									// onClick={handleClick}
									className="sign-in-button hvr-shutter-in-vertical"
								>
									SIGN IN
								</button>
							</div>
						) : (
							<div style={{buttonsStyle}}>
								<button
									// onClick={handleClick}
									className="sign-in-button hvr-shutter-in-vertical"
								>
									SIGNING IN
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
			</form>
			)
		</>
	);
};

export default Login;
