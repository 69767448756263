import React, { useState, useEffect } from "react";
import Collapse from "@material-ui/core/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LanguageIcon from "@mui/icons-material/Language";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { makeStyles } from "@material-ui/core/styles";
import { useRecoilState } from "recoil";
import {
	dashID as dashIDAtom,
	loading as loadingAtom,
	openPanel as openPanelAtom,
	selectedDash as selectedDashAtom,
	dashType as dashTypeAtom,
} from "./Atoms";
import { ReactComponent as Shirt } from "../images/t-shirt-outline.svg";
import { ReactComponent as Retail } from "../images/shopping-cart-empty-side-view.svg";
import { ReactComponent as Logistics } from "../images/logistics.svg";
import { ReactComponent as Internal } from "../images/home-icon-silhouette-svgrepo-com.svg";
import { ReactComponent as Invoice } from "../images/SAP.svg";
import { ReactComponent as ServiceDesk } from "../images/settings.svg";
import { ReactComponent as Dashboard } from "../images/dashboard-report-icon.svg";

const Panel = ({ department, dashboards, mainOpen, setMainOpen }) => {
	const useStyles = makeStyles((theme) => ({
		root: {
			width: "100%",
			backgroundColor: "#333333",
			color: "white",
			// visibility: sidebarOpen? "visible": "hidden"
			// paddingLeft: "10px",
		},
		nested: {
			height: "48px !important",
			paddingLeft: theme.spacing(4),
			fontSize: "14px",
			color: "#999999 !important",
			fontWeight: 500,
			fontFamily: "Montserrat, sans-serif",
		},

		heading: {
			fontSize: theme.typography.pxToRem(15),
			flexBasis: "33.33%",
			flexShrink: 0,
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
	}));

	const [open, setOpen] = useState(false);
	const [dashboardList, setDashboardList] = useState([]);
	const [dashID, setDashID] = useRecoilState(dashIDAtom);
	const [loading, setLoading] = useRecoilState(loadingAtom);
	const [openPanel, setOpenPanel] = useRecoilState(openPanelAtom);
	const [selectedDash, setSelectedDash] = useRecoilState(selectedDashAtom);
	const [dashType, setDashType] = useRecoilState(dashTypeAtom);
	const classes = useStyles();

	const handleDash = (id, name, type) => {
		if (dashID === id) {
		} else {
			console.log(type);
			setLoading(true);
			setSelectedDash(name);
			setDashID(id);
			if (type === undefined) {
				setDashType("QUICKSIGHT");
			} else {
				setDashType(type);
			}
		}
	};

	const icons = {
		HR: (
			<SupervisedUserCircleIcon
				style={{ fill: openPanel !== "HR" ? "#EFB758" : "#FFF" }}
			/>
		),
		Finance: (
			<AttachMoneyIcon
				style={{ fill: openPanel !== "Finance" ? "#EFB758" : "#FFF" }}
			/>
		),
		Merchandise: (
			<Shirt
				style={{
					fill: openPanel !== "Merchandise" ? "#EFB758" : "#FFF",
					width: "20px",
				}}
			/>
		),
		"Retail Ops": (
			<Retail
				style={{
					fill: openPanel !== "Retail Ops" ? "#EFB758" : "#FFF",
					width: "20px",
				}}
			/>
		),
		Web: (
			<LanguageIcon
				style={{
					fill: openPanel !== "Web" ? "#EFB758" : "#FFF",
					width: "20px",
				}}
			/>
		),
		Uploads: (
			<DriveFolderUploadIcon
				style={{
					fill: openPanel !== "Uploads" ? "#EFB758" : "#FFF",
					width: "20px",
				}}
			/>
		),
		Logistics: (
			<Logistics
				style={{
					fill: openPanel !== "Logistics" ? "#EFB758" : "#FFF",
					width: "20px",
				}}
			/>
		),
		Internal: (
			<Internal
				style={{
					fill: openPanel !== "Internal" ? "#EFB758" : "#FFF",
					width: "20px",
				}}
			/>
		),
		"Service Desk": (
			<ServiceDesk
				style={{
					fill: openPanel !== "Service Desk" ? "#EFB758" : "#FFF",
					width: "20px",
				}}
			/>
		),
		"SAP Extracts": (
			<Invoice
				style={{
					fill: openPanel !== "SAP Extracts" ? "#EFB758" : "#FFF",
					width: "20px",
				}}
			/>
		),
		"Business Intelligence": (
			<Dashboard
				style={{
					fill: openPanel !== "SAP Extracts" ? "#EFB758" : "#FFF",
					width: "20px",
				}}
			/>
		),
	};

	useEffect(() => {
		// console.log(dashboards);
		const dashList = dashboards.filter((dash) => {
			return dash.department_name === department;
		});
		setDashboardList(dashList);
	}, []);

	useEffect(() => {
		if (!mainOpen) {
			setOpen(false);
		}
	}, [mainOpen]);

	useEffect(() => {
		if (openPanel === department) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [openPanel]);

	function handleClick() {
		if (openPanel === department) {
			setOpen(!open);
		}
		setOpenPanel(department);
		setMainOpen(true);
	}

	return (
		<>
			<ListItem
				className="list-item"
				alignItems="flex-start"
				button
				divider={true}
				onClick={handleClick}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
						margin: "0 auto",
						height: "64px",
					}}
				>
					<ListItemIcon style={{ transform: "translateY(-4px)" }}>
						{icons[department]}
					</ListItemIcon>
					<ListItemText
						style={{
							fontSize: department.length > 15 ? "1rem" : "inherit",
						}}
						disableTypography={true}
						primary={department}
					/>
				</div>
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{dashboardList.map((dash) => {
						return (
							<ListItem
								button
								key={dash.dashboard_name}
								className={classes.nested}
							>
								<ListItemText
									// inset={true}
									disableTypography={true}
									primary={
										dash.display_name === null
											? dash.dashboard_name
											: dash.display_name
									}
									style={{
										color:
											dash.dashboard_name === selectedDash ? "#efb758" : "grey",
										visibility: mainOpen ? "visible" : "hidden",
									}}
									onClick={() => {
										handleDash(
											dash.dashboard_id,
											dash.dashboard_name,
											dash.dashboard_type
										);
									}}
								/>
							</ListItem>
						);
					})}
				</List>
			</Collapse>
		</>
	);
};

export default Panel;
