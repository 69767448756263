import React, { useState, useEffect } from "react";
import axios from "axios";
import Drawer from "./Drawer";
import { Auth } from "aws-amplify";
import { useRecoilState } from "recoil";
import {
	noDashboards as noDashboardsAtom,
	dashID as dashIDAtom,
	loading as loadingAtom,
	dashType as dashTypeAtom,
	brand as brandAtom,
} from "./Atoms";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import Spinner from "../images/Spinner.svg";
import Uploads from "./Uploads";
import { useQuery } from "react-query";
import "./Workspace.css";
import Query from "./Query";
import Container from "./Container";
import Iframe from "react-iframe";
import Downloads from "./Downloads";

const Workspace = ({ setAuthenticated }) => {
	const style = {
		display: "flex",
		flexDirection: "row",
		height: "100vh",
	};

	const [dashID, setDashID] = useRecoilState(dashIDAtom);
	const [loading, setLoading] = useRecoilState(loadingAtom);
	const [dashType, setDashType] = useRecoilState(dashTypeAtom);
	const [noDashboards, setNoDashboards] = useRecoilState(noDashboardsAtom);
	const [brand, setBrand] = useRecoilState(brandAtom);
	const [quicksightUrl, setUrl] = useState("");

	function handleLoad() {
		// console.log(loading);
	}

	async function getBrand() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const response = await axios.get(
			`${process.env.REACT_APP_ENDPOINT}/brand?email=${user.username}`, {
				headers: {
					Authorization: idToken,
				},
			}
		);

		setBrand(response.data[0].brand);
		return response.data;
	}

	// useEffect(() => {
	// 	async function getQuery() {
	// 		const response = await axios.get(
	// 			`${process.env.REACT_APP_ENDPOINT}/query`
	// 		);
	// const csv = jsonToCSV(response.data, {
	// 	header: true,
	// 	delimiter: ",",
	// });
	// 		console.log(csv);
	// 	}
	// 	getQuery();
	// }, []);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			setLoading(true);
			if (dashID === "1") {
				setDashType("UPLOAD");
				setLoading(false);
			}
			if (dashID === "2") {
				setDashType("EXTRACT");
				setLoading(false);
			} 
			if (dashID === "3") {
				setDashType("DOWNLOADS");
				setLoading(false);
			}
			else {
				if (brand !== "") {
					getURL();
				}
			}
			async function getURL() {
				const user = await Auth.currentAuthenticatedUser();
				const idToken = user.signInUserSession.idToken.jwtToken;
				if (dashID !== "") {
					// let u = `${process.env.REACT_APP_ENDPOINT}/dashboard/${dashID}#p.Brand=${brand}`;
					const response = await axios.get(
						`${process.env.REACT_APP_ENDPOINT}/dashboard/${dashID}`, {
							headers: {
								Authorization: idToken,
							},
						}
					);
					console.log(response)
					setUrl(response.data.EmbedUrl + `#p.Brand=${brand}`);
					setLoading(false);
				}
			}
		}
		return () => (mounted = false);
	}, [dashID, brand]);

	useEffect(() => {
		console.log(quicksightUrl);
	}, [quicksightUrl]);

	useEffect(() => {
		setLoading(false);
		if (noDashboards === true) {
			setLoading(false);
			// alert("No dashboards assigned. Please contact Admin.")
			// signOut();
		}
	}, [noDashboards]);

	async function signOut() {
		try {
			await Auth.signOut();
			setAuthenticated(false);
		} catch (error) {
			console.log("error signing out: ", error);
		}
	}

	const brandQuery = useQuery("brand", getBrand);

	if (noDashboards === true)
		return (
			<div style={style}>
				<Drawer setAuthenticated={setAuthenticated} />
				<div
					style={{
						height: "100%",
						width: "100%",
						backgroundColor: "white",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Container />
				</div>
			</div>
		);

	if (dashType === "EXTRACT") {
		return (
			<div style={style}>
				<Drawer setAuthenticated={setAuthenticated} />
				<div style={{ width: "100vw" }}>
					{loading ? (
	<div
		style={{
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}
	>
		<img src={Spinner} alt="Spinner" />
	</div>
					) : (
						<Query />
					)}
				</div>
			</div>
		);
	}

	if (dashType === "DOWNLOADS") {
		return (
			<div style={style}>
				<Drawer setAuthenticated={setAuthenticated} />
				<div style={{ width: "100vw" }}>
					{loading ? (
	<div
		style={{
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}
	>
		<img src={Spinner} alt="Spinner" />
	</div>
					) : (
						<Downloads />
					)}
				</div>
			</div>
		);
	}

	return (
		<div style={style}>
			<Drawer setAuthenticated={setAuthenticated} />
			{/* <Container loading={loading} dashType={dashType} url={url} /> */}
			<div style={{ width: "100vw" }}>
				{loading ? (
					<div
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img src={Spinner} alt="Spinner" />
					</div>
				) : dashType === "QUICKSIGHT" ? (
					<Iframe
						url={quicksightUrl}
						width="100%"
						height="100%"
						id="myId"
						className="myClassname"
						display="initial"
						position="relative"
					/>
					// <iframe
					// 	title="dashboard"
					// 	width="100%"
					// 	height="100%"
					// 	id="myId"
					// 	className="myClassname"
					// 	display="initial"
					// 	position="relative"
					// 	url={quicksightUrl}
					// ></iframe>
				) : (
					// <h2>HEllo</h2>
					<Uploads />
				)}
			</div>
		</div>
	);
};

export default Workspace;
