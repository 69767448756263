import React from 'react'
import Budgets from './Budgets';

const Uploads = () => {
    return (
        <Budgets />
    )
}

export default Uploads;
