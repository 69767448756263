import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Button } from "semantic-ui-react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
// import {
// 	setPasswordModal as setPasswordModalAtom,
// 	disableUserModal as disableUserModalAtom,
// 	deleteUserModal as deleteUserModalAtom,
// 	currentPoolName as currentPoolNameAtom,
// 	currentPool as currentPoolAtom,
// 	addUserModal as addUserModalAtom,
// 	resetPasswordModal as resetPasswordModalAtom,
// 	selectedUser as selectedUserAtom,
// } from "./Atoms.js";

const DocumentList = () => {
	const tableStyle = {
		height: "100vh",
		overflowY: "scroll",
		zIndex: 0,
		marginBottom: "10vh",
    };
    
    const [data, setData] = useState([
	{
		file: "User-Manual.pdf"
	},
	{
		file: "Template.csv"
	},
	{
		file: "location_codes.xlsx"
	}
	]) 
	
	useEffect(() => {
		console.log(data);
	}, [data])

	// console.log(data);
	const style = {
		textAlign: "left",
		fontFamily: '"Montserrat", sans-serif',
	};
	async function download(rowData) {
		console.log(rowData);
		const id = rowData.file;
		console.log(id);
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_ENDPOINT}/documents/${id}`,
				{
					headers: {
						Authorization: idToken
					},
				}
            );
            console.log(response);
			if (response.status === 200) {
				const link = document.createElement('a');
				link.href = response.data.uploadURL;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
		catch (e) {
			toast.error(e);
		}
	}


	const [state, setState] = useState({
		columns: [
			{
				title: "File",
				field: "file",
				cellStyle: style,
			}
		],
	});

	return (
		<div style={tableStyle}>
			<div style={{display: "flex", padding:"20px"}}>
				<a style={{}} href="https://lynkd.atlassian.net/servicedesk/">Service Desk</a>
			</div>

			<div>
				<MaterialTable
					title={``}
					columns={state.columns}
					data={data}
					style={tableStyle}
					actions={[
						rowData => ({
							icon: 'save',
							tooltip: 'Download',
							onClick: (event, rowData) => {
							  download(rowData);
							},
							disabled: rowData.status === 'Processing'
						  })
					  ]}
					options={{
						search: true,
						filtering: false,
						paging: false,
						actionsColumnIndex: 8,
						sorting: true,
						headerStyle: {
							textAlign: "left",
						},
					}}
				/>
			</div>
		</div>
	);
};

export default DocumentList;
