import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Button } from "semantic-ui-react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
// import {
// 	setPasswordModal as setPasswordModalAtom,
// 	disableUserModal as disableUserModalAtom,
// 	deleteUserModal as deleteUserModalAtom,
// 	currentPoolName as currentPoolNameAtom,
// 	currentPool as currentPoolAtom,
// 	addUserModal as addUserModalAtom,
// 	resetPasswordModal as resetPasswordModalAtom,
// 	selectedUser as selectedUserAtom,
// } from "./Atoms.js";

const QueryList = ({ data }) => {
	const tableStyle = {
		height: "100vh",
		overflowY: "scroll",
		zIndex: 0,
		marginBottom: "10vh",
		// display: "flex",
		// flexDirection: "column",
		// justifyContent: "center",
		// alignItems: "center",
		// backgroundColor: "yellow"
	};

	// console.log(data);
	const style = {
		textAlign: "left",
		fontFamily: '"Montserrat", sans-serif',
	};

	function checkProcessing(status) {
		if (status === 'Processing') {
			return true;
		}

		else {
			return false;
		}
	}

	async function download(rowData) {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		console.log(rowData);
		let brand = rowData.brand;
		let start = rowData.range.substring(0,10);
		let end = rowData.range.slice(-10);
		let query = `brand=${brand}&start=${start}&end=${end}`;
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_ENDPOINT}/signedurl?${query}`,
				{
					headers: {
						Authorization: idToken,
					},
				}
			);
			if (response.status === 200) {
				const link = document.createElement('a');
				link.href = response.data.uploadURL;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				toast.success(`${brand}: ${start}-${end}.csv downloaded.`)
			}
		}
		catch (e) {
			toast.error(e);
		}
	}


	const [state, setState] = useState({
		columns: [
			{
				title: "User",
				field: "email",
				cellStyle: style,
			},
			{
				title: "Added",
				field: "added_date",
				cellStyle: style,
				defaultSort: "desc",
			},
			{
				title: "Brand",
				field: "brand",
				cellStyle: style,
			},
			{
				title: "Range",
				field: "range",
				cellStyle: style,
			},
			{
				title: "Status",
				field: "status",
				cellStyle: style
			}
		],
	});

	return (
		<div style={tableStyle}>
			<div>
				<MaterialTable
					title={``}
					columns={state.columns}
					data={data}
					style={tableStyle}
					actions={[
						rowData => ({
							icon: 'save',
							tooltip: 'Download',
							onClick: (event, rowData) => {
							  download(rowData);
							},
							disabled: rowData.status === 'Processing'
						  })
					  ]}
					options={{
						// rowStyle: rowData => ({
						// 	backgroundColor: selected === rowData.tableData.id ? "#EEE" : "#FFF"
						// }),
						// toolbar: false,
						// rowStyle: (rowData) => ({
						// 	backgroundColor:
						// 		selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
						// }),
						search: true,
						filtering: false,
						paging: false,
						actionsColumnIndex: 8,
						sorting: true,
						headerStyle: {
							textAlign: "left",
						},
					}}
				/>
			</div>
		</div>
	);
};

export default QueryList;
