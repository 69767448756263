import React, { useState, useRef } from "react";
import BudgetsList from "./BudgetsList";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { CSVReader } from "react-papaparse";
// import CSVReader from "react-csv-reader";
import { Auth } from "aws-amplify";
import { Button, Select } from "semantic-ui-react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ReactComponent as Spinner } from "../images/Spinner.svg";
import { toast } from "react-toastify";
import { hijackEffects } from "stop-runaway-react-effects";

const Budgets = () => {
	const queryClient = useQueryClient();
	const readerRef = useRef();
	const [sheetData, setSheetData] = useState([]);
	const [filename, setFilename] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const [comment, setComment] = useState("");
	const [loading, setLoading] = useState(false);
	const [department, setDepartment] = useState("");
	const [queryModal, setQueryModal] = useState(false);
	const [complete, setComplete] = useState(false);
	const [parsing, setParsing] = useState(false);

	function handleDepartment(event, data) {
		// console.log(data.value);
		setDepartment(data.value);
	}

	// hijackEffects();

	const dataRef = useRef([]);

	const papaparseOptions = {
		header: true,
		dynamicTyping: true,
		skipEmptyLines: true,
	};

	const handleOnDrop = (data, fileInfo, originalFile) => {
		console.log(data);
		setFilename(fileInfo.name);
		// let final = data.map((d) => {
		// 	return d.data;
		// });
		console.log(fileInfo);
		setSheetData(data);
		setComplete(true);
	};

	function handleStream(data) {
		// setParsing(true);
		// console.log(data);
		let d = [...dataRef.current];
		d.push(data);
		dataRef.current = d;
		// setSheetData(d);
	}

	const handleComment = (event) => {
		setComment(event.target.value);
	};

	function isFinance(csv) {
		let isFinance = false;
		csv.forEach((row) => {
			if (row.fin_budget !== "") {
				isFinance = true;
			}
			if (row.fin_forecast !== "") {
				isFinance = true;
			}
			if (row.fin_forecast_rolling !== "") {
				isFinance = true;
			}
		});
		return isFinance;
	}

	function checkDepartment(data, department) {
		let valid = false;
		// console.log(data);
		let columns = Object.keys(data[0]).length;
		if (columns < 24) {
			toast.error("Insufficient amount of columns provided");
		} else {
			if (department === "Finance") {
				data.forEach((row) => {
					console.log(row);
					if (
						row.merch_budget === "" &&
						row.merch_forecast === "" &&
						row.merch_forecast_rolling === "" &&
						row.retail_target === "" &&
						row.retail_target_stretch === "" &&
						row.web_target === ""
					) {
						valid = true;
					}
				});
			}
			if (department === "Merchandise") {
				data.forEach((row) => {
					if (
						row.fin_budget === "" &&
						row.fin_forecast === "" &&
						row.fin_forecast_rolling === "" &&
						row.retail_target === "" &&
						row.retail_target_stretch === "" &&
						row.web_target === ""
					) {
						valid = true;
					}
				});
			}
			if (department === "Retail Ops") {
				data.forEach((row) => {
					if (
						row.fin_budget === "" &&
						row.fin_forecast === "" &&
						row.fin_forecast_rolling === "" &&
						row.merch_budget === "" &&
						row.merch_forecast === "" &&
						row.merch_forecast_rolling === "" &&
						row.web_target === ""
					) {
						valid = true;
					}
				});
			}
			if (department === "Web") {
				data.forEach((row) => {
					if (
						row.fin_budget === "" &&
						row.fin_forecast === "" &&
						row.fin_forecast_rolling === "" &&
						row.merch_budget === "" &&
						row.merch_forecast === "" &&
						row.merch_forecast_rolling === "" &&
						row.retail_target === "" &&
						row.retail_target_stretch === "" &&
						row.retail_target === ""
					) {
						valid = true;
					}
				});
			}
			return valid;
		}
	}

	const handleSubmit = async () => {
		setFilename(readerRef.current.fileNameInfoRef.current.innerHTML);
		let isFinanceVar;
		setSubmitting(true);
		if (department === "") {
			toast.error("Please select a department.");
		}
		if (sheetData === undefined) {
			toast.error("Please select a CSV file.");
		} else if (department !== "" && sheetData !== undefined) {
			const user = await Auth.currentAuthenticatedUser();
			const idToken = user.signInUserSession.idToken.jwtToken;
			const departmentColumn = { department: department };

			let newData = sheetData.map((entry) => {
				return { ...departmentColumn, ...entry };
			});
			let valid = checkDepartment(sheetData, department);
			if (valid) {
				const body = {
					data: newData,
					user: user.username,
					filename: filename,
					comment: comment,
					department: department,
				};
				try {
					const response = await axios.post(
						`${process.env.REACT_APP_ENDPOINT}/budget`,
						body,
						{
							headers: {
								Authorization: idToken,
							},
						}
					);
					console.log(response.data);
					if (response.data.length === 0) {
						toast.success(`Successfully added ${filename}.`);
						readerRef.current.removeFile();
						setComment("");
						setComplete(false);
					}
					if (response.data.length > 0) {
						let invalids = response.data;
						console.log(invalids);
						toast.error(
							`The following codes were invalid for location_dim_2: ${[
								...invalids,
							].join(",")}`
						);
						readerRef.current.removeFile();
						setComment("");
						setComplete(false);
					}
					if (response.data?.originalError) {
						toast.error(response.data?.originalError.info.message);
					}
				} catch (e) {
					console.log(e);
					toast.error("Failed");
					setSubmitting(false);
				}
			} else {
				toast.error("Data not valid for selected department.");
			}
		}
		setSubmitting(false);
	};

	async function getLog() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const data = await axios.get(`${process.env.REACT_APP_ENDPOINT}/log`, {
			headers: {
				Authorization: idToken,
			},
		});
		// console.log(data.data);
		return data.data;
	}

	async function getDepartments() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const data = await axios.get(
			`https://9pois0apae.execute-api.af-south-1.amazonaws.com/dev/departments`,
			{
				headers: {
					Authorization: idToken,
				},
			}
		);
		const dep = data.data.map((d) => {
			return {
				key: d.department_name,
				value: d.department_name,
				text: d.department_name,
			};
		});
		return dep;
	}

	const log = useQuery("log", getLog);
	const departments = useQuery("departments", getDepartments);

	const mutate = useMutation(handleSubmit, {
		onSuccess: () => {
			queryClient.invalidateQueries("log");
		},
	});

	const departmentSelect = [
		{ key: "finance", value: "fin", text: "Finance" },
		{ key: "merchandise", value: "merch", text: "Merchandise" },
		{ key: "retail", value: "retail", text: "Retail Ops" },
		{ key: "web", value: "web", text: "Web" },
	];

	return (
		<div
			style={{
				backgroundColor: "white",
				display: "flex",
				flexDirection: "column",
			}}
		>
			{/* <CSVReader
				cssClass="csv-reader-input"
				label="Select CSV"
				onFileLoaded={handleOnDrop}
				parserOptions={papaparseOptions}
				inputId="ObiWan"
				inputName="ObiWan"
				inputStyle={{ color: "red" }}
			/> */}
			<CSVReader
				onDrop={handleOnDrop}
				ref={readerRef}
				noDrag
				addRemoveButton
				config={{
					header: true,
					skipEmptyLines: "greedy",
					worker: true,
					step: function (results, parser) {
						handleStream(results.data);
					},
					complete: function (results, file) {
						setSheetData(dataRef.current);
						setParsing(false);
						setComplete(true);
					},
				}}
			>
				<span>Click to upload.</span>
			</CSVReader>
			<div
				style={{
					marginTop: "2vh",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<TextField
					onChange={handleComment}
					style={{ width: "40vw" }}
					multiline
					rows={4}
					value={comment}
					id="outlined-basic"
					label="Comment"
					variant="outlined"
				/>
				<Select
					placeholder="Department"
					style={{ marginTop: "2vh", zIndex: "5" }}
					options={departments.data}
					onChange={handleDepartment}
				/>
			</div>
			<div style={{ height: "10vh" }}>
				{submitting ? (
					<Spinner style={{ height: "80px" }} />
				) : (
					<Button
						disabled={complete ? false : true}
						secondary
						style={{ margin: "20px" }}
						onClick={mutate.mutate}
					>
						{parsing ? "Parsing" : "Submit"}
					</Button>
				)}
			</div>
			{loading ? (
				<img src={Spinner} alt="Spinner" />
			) : (
				<BudgetsList data={log.data} />
			)}
		</div>
	);
};

export default Budgets;
