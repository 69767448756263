import { atom, selector } from "recoil";

export const sidebarOpen = atom({
	key: "sidebarOpen",
	default: false,
});

export const sidebar = atom({
	key: "sidebar",
	default: true,
});

export const dashID = atom({
	key: "dashID",
	default: "",
});

export const loading = atom({
	key: "loading",
	default: true,
});

export const openPanel = atom({
	key: "openPanel",
	default: "",
});

export const selectedDash = atom({
	key: "selectedDash",
	default: "",
});

export const dashType = atom({
	key: "dashType",
	default: "QUICKSIGHT",
});

export const noDashboards = atom({
	key: "noDashboards",
	default: false,
});

export const brand = atom({
	key: "brand",
	default: "",
});
