import { useEffect } from "react";
import { useState } from "react";
import "./App.css";
import Login from "./components/Login/Login";
import Workspace from "./components/Workspace";
import { RecoilRoot } from "recoil";
import Amplify from "aws-amplify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "./images/logo-pattern-analytics.svg";
import { Auth } from "aws-amplify";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";

Amplify.configure({
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: "eu-west-1",

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: process.env.REACT_APP_USER_POOL_ID,

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		authenticationFlowType: "USER_PASSWORD_AUTH",
	},
});

function App() {
	const [authenticated, setAuthenticated] = useState(false);
	const queryClient = new QueryClient();

	useEffect(() => {
		onLoad();
	}, []);

	async function onLoad() {
		try {
			// await Auth.currentSession().then((data) => console.log(data));
			// const user = await Auth.createCognitoUser();
			// console.log(user);
			const user = await Auth.currentAuthenticatedUser();
			// console.log(user);
			setAuthenticated(true);
		} catch (e) {
			if (e !== "No current user") {
				// alert(e);
			}
		}
	}

	return (
		<div className="App">
			<QueryClientProvider client={queryClient}>
				<RecoilRoot>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
					{authenticated ? (
						<Workspace setAuthenticated={setAuthenticated} />
					) : (
						<>
							<img
								style={{ transform: "scale(0.8)", marginTop: "5vh" }}
								className="login-logo"
								src={Logo}
								alt="login-logo"
							/>
							<Login
								authenticated={authenticated}
								setAuthenticated={setAuthenticated}
							/>
						</>
					)}
				</RecoilRoot>
			</QueryClientProvider>
		</div>
	);
}

export default App;
