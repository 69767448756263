import React, { useState, useEffect } from "react";
import MaterialTable, {MTableGroupbar} from "material-table";
// import {
// 	setPasswordModal as setPasswordModalAtom,
// 	disableUserModal as disableUserModalAtom,
// 	deleteUserModal as deleteUserModalAtom,
// 	currentPoolName as currentPoolNameAtom,
// 	currentPool as currentPoolAtom,
// 	addUserModal as addUserModalAtom,
// 	resetPasswordModal as resetPasswordModalAtom,
// 	selectedUser as selectedUserAtom,
// } from "./Atoms.js";

const BudgetsList = ({ data }) => {
	console.log(data);
	const tableStyle = {
		height: "100vh",
		overflowY: "scroll",
		zIndex: 0,
		marginBottom: "10vh",
		// display: "flex",
		// flexDirection: "column",
		// justifyContent: "center",
		// alignItems: "center",
		// backgroundColor: "yellow"
	};

	// console.log(data);
	const style = {
		textAlign: "left",
		fontFamily: '"Montserrat", sans-serif',
	};

	const [state, setState] = useState({
		columns: [
			{
				title: "User",
				field: "email",
				cellStyle: style,
			},
			{
				title: "Added",
				field: "added_date",
				cellStyle: style,
				defaultSort: "desc",
			},
			{
				title: "Department",
				field: "department",
				cellStyle: style,
				defaultGroupOrder: 0
			},
			{
				title: "Filename",
				field: "filename",
				cellStyle: style,
			},
			{
				title: "Comment",
				field: "comment",
				cellStyle: style,
			},
			{
				title: "ID",
				field: "id",
				cellStyle: style,
			},
			{
				title: "Status",
				field: "status",
				cellStyle: style,
			},
			// {
			// 	title: "Created",
			// 	field: "created",
			// 	cellStyle: {
			// 		width: "12vw",
			// 		textAlign: "left",
			// 	},
			// 	headerStyle: {
			// 		textAlign: "center",
			// 	},
			// },
			// {
			// 	title: "Last Modified",
			// 	field: "last_modified",
			// 	cellStyle: style,
			// },
		],
	});

	return (
		<div style={tableStyle}>
			<div>
				<MaterialTable
					title={`Budgets`}
					columns={state.columns}
					data={data}
					style={tableStyle}
					components={{
						Groupbar: (props) => (
							<div style={{ height: 0, visibility: "hidden" }}>
								<MTableGroupbar
									{...props}
								/>
							</div>
						),
					}}
					options={{
						// rowStyle: rowData => ({
						// 	backgroundColor: selected === rowData.tableData.id ? "#EEE" : "#FFF"
						// }),
						// toolbar: false,
						// rowStyle: (rowData) => ({
						// 	backgroundColor:
						// 		selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
						// }),
						search: true,
						// grouping: true,
						filtering: false,
						paging: false,
						actionsColumnIndex: 8,
						sorting: true,
						headerStyle: {
							textAlign: "left",
						},
					}}
				/>
			</div>
		</div>
	);
};

export default BudgetsList;
