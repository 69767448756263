import React, { useState, useEffect } from "react";
import axios from "axios";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Auth } from "aws-amplify";
import { Button, Select, Divider, Form, Label } from "semantic-ui-react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ReactComponent as Spinner } from "../images/Spinner.svg";
import { toast } from "react-toastify";
import QueryList from "./QueryList";

const Query = () => {
	const queryClient = useQueryClient();
	const [submitting, setSubmitting] = useState(false);
	const [currentRange, setNewRange] = useState(null);
	const [start, setStart] = useState("");
	const [today, setToday] = useState("");
	const [max, setMax] = useState(null);
	const [brand, setBrand] = useState("");
	const [jobID, setJobID] = useState("");
	const [disabled, setDisabled] = useState(true);
	const [loading, setLoading] = useState(true);
	const [upToDate, setUpToDate] = useState(true);

	async function getLog() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const data = await axios.get(`${process.env.REACT_APP_ENDPOINT}/extractlog`, {
			headers: {
				Authorization: idToken,
			},
		});
		// console.log(data.data);
		let updated = true;
		data.data.forEach(entry => {
			if (entry.status === "Processing") {
				updated = false;
			}
		});
		setUpToDate(updated);
		return data.data;
	}

	const log = useQuery("log", getLog);

	async function check(brand, start, end, job_id) {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		let query = `brand=${brand}&start=${start}&end=${end}&job_id=${job_id}`;
		const data = await axios.get(`${process.env.REACT_APP_ENDPOINT}/echeck?${query}`, {
			headers: {
				Authorization: idToken,
			},
		});
		if (data.data.statusCode !== 403) {
			console.log(data.data);
		}
		queryClient.invalidateQueries("log");
		return data.data;
	}

	useEffect(() => {
		let date = new Date(2021,0,1,0,0,0);
		let now = new Date();
		setToday(now);
		// date.setDate(date.getDate() - 60)
		console.log(date);
		setStart(date);
	}, [])

	useEffect(() => {
		if (brand !== "" && currentRange !== null) {
			setDisabled(false);
		}
		else {
			setDisabled(true);
		}
	}, [brand, currentRange])


	const onChange = (event, data) => {
		setNewRange(data.value);
		if (data.value !== null) {
			const max = new Date(data?.value[0]);
			max.setDate(start.getDate() + 180);
			setMax(max)
			console.log(max);
		}

	} 

	const brandSelect = [
		{ key: "CROCS", value: "CROCS", text: "Crocs" },
		{ key: "RSCE", value: "RSCE", text: "Birkenstock" },
		{ key: "UA", value: "UA", text: "Under Armour" },
		{ key: "2XU", value: "2XU", text: "2XU" },
	];

	function handleBrand(event, data) {
		setBrand(data.value);
	}

	function handleZero(date) {
		if (date < 10) {
			return '0' + date;
		}
		else {
			return date;
		}
	}

	const handleSubmit = async () => {
		if (brand === "") {
			toast.error("Please select a brand.")
		}
		else if (currentRange === "") {
			toast.error("Please select a date range.")
		} 
		else {
			setSubmitting(true);
			const user = await Auth.currentAuthenticatedUser();
			let start = currentRange[0].getFullYear()+'-'+ handleZero(currentRange[0].getMonth()+1)+'-'+ handleZero(currentRange[0].getDate());
			let end = currentRange[1].getFullYear()+'-'+ handleZero(currentRange[1].getMonth()+1)+'-'+ handleZero(currentRange[1].getDate());
			const idToken = user.signInUserSession.idToken.jwtToken;
			let query = `brand=${brand}&start=${start}&end=${end}`;
			const body = {
				user: user.username,
				start: start,
				end: end,
				brand: brand
			}
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_ENDPOINT}/elog`,body,
					{
						headers: {
							Authorization: idToken,
						},
					}
				);
				console.log(response);
				let job_id = response;
				if (response.data?.originalError) {
					toast.error(response.data?.originalError.message);
				}
				if (response.status === 200) {
					setNewRange(null);
					setBrand("");
					toast.success(`${brand}: ${start} - ${end} added to queue.`)
					setUpToDate(false);
					// check(brand, start, end, job_id);
				}
			} catch (e) {
				console.log(e);
				toast.error("Failed");
				setSubmitting(false);
			}
			setMax(null);
			setSubmitting(false);
		}

	};

	const mutate = useMutation(handleSubmit, {
		onSuccess: () => {
			queryClient.invalidateQueries("log");
		},
	});

	const extractCheck = useQuery("extract_check", async () => {
		const queue = log.data.map(entry => {
			if (entry.status === "Processing") {
				let brand = entry.brand;
				let start = entry.range.substring(0,10);
				let end = entry.range.slice(-10);
				let job_id = entry.job_id;
				check(brand, start, end, job_id);
			}
		});
		// setUpToDate(true);
	}, {
		refetchInterval: 10000,
		enabled: !upToDate
	})

	return (
		<div
			style={{
				backgroundColor: "white",
				display: "flex",
				flexDirection: "column",
				height: "100vh"
			}}
		>	<div style={{alignSelf: "flex-start", margin: "20px"}}>
				<h1>Invoice Analysis</h1>
			</div>
			<Divider />
			<div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						marginLeft: "20px"
					}}
				>
					<Form>
						<Form.Field>
							<Select
									placeholder="Brand"
									style={{ marginTop: "2vh", zIndex: "5", maxWidth: "215px", textAlign: "center" }}
									options={brandSelect}
									onChange={handleBrand}
									value={brand}
								/>
							<Label style={{marginTop: "20px"}} pointing>Please select a brand</Label>
						</Form.Field>
					</Form>
					<Form>
						<Form.Field>
							<div style={{marginTop: "2vh", minWidth: "200px", display: "flex", flexDirection: "column"}}>
								<SemanticDatepicker showToday={false} minDate={start} maxDate={max} onChange={onChange} type="range" value={currentRange}/>
								<Label pointing>Please select a date range</Label>
							</div>
						</Form.Field>
					</Form>


				</div>
				<div style={{ height: "10vh" }}>
					{submitting ? (
						<Spinner style={{ height: "80px" }} />
					) : (
						<Button disabled={disabled} secondary style={{ margin: "20px" }} onClick={mutate.mutate}>
							Submit
						</Button>
					)}
				</div>
			</div>

				<div>
					{log.isLoading ? (
						// <img src={Spinner} alt="Spinner" />
						<p>Loading...</p>
					) : (
						<QueryList data={log.data} />
					)}
				</div>
		</div>
	);
};

export default Query;
