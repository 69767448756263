import React from "react";
import { useRecoilState } from "recoil";
import Iframe from "react-iframe";
import {
	noDashboards as noDashboardsAtom,
	dashID as dashIDAtom,
	loading as loadingAtom,
	dashType as dashTypeAtom,
	brand as brandAtom,
} from "./Atoms";
import Spinner from "../images/Spinner.svg";
import Uploads from "./Uploads";

const Container = ({ loading, dashType, url }) => {
	const [dashID, setDashID] = useRecoilState(dashIDAtom);
	// const [loading, setLoading] = useRecoilState(loadingAtom);
	// const [dashType, setDashType] = useRecoilState(dashTypeAtom);
	const [noDashboards, setNoDashboards] = useRecoilState(noDashboardsAtom);
	const [brand, setBrand] = useRecoilState(brandAtom);

	if (loading) {
		return (
			<div
				style={{
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100vw",
				}}
			>
				<img src={Spinner} alt="Spinner" />
			</div>
		);
	}

	if (dashType === "UPLOADS") {
		return <Uploads />;
	}

	return (
		<h2>Hello</h2>
		// <Iframe
		// 	url={url}
		// 	width="100%"
		// 	height="100%"
		// 	id="myId"
		// 	className="myClassname"
		// 	display="initial"
		// 	position="relative"
		// />
	);
};

export default Container;
